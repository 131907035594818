.hero {
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 20px;
    box-sizing: border-box;
}

.hero-left {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    margin-bottom: 100px;
    margin-top: 30px;
}

.banner {
    width: 1100px;
    max-width: 90%;
    height: auto;


}

.hero-left h2 {
    color: #090909;
    font-size: 20px;
    font-weight: 600;
}

.hero-left p {
    color: #171717;
    font-size: 80px;
    font-weight: 700;
    margin: 10px 0;
}

.hero-hand-icon {
    display: flex;
    align-items: center;
    gap: 20px;
}

.hero-hand-icon img {
    width: 105px;
}

.hero-right {
    display: none;
}

@media (max-width: 768px) {
    .hero-left h2 {
        font-size: 18px;
    }

    .hero-left p {
        font-size: 50px;
    }

    .hero-hand-icon img {
        width: 80px;
    }
}

@media (max-width: 480px) {
    .hero-left h2 {
        font-size: 16px;
    }

    .hero-left p {
        font-size: 30px;
    }

    .hero-hand-icon img {
        width: 60px;
    }

    .hero-right {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .hero-right img {
        width: 300px;
    }
}
