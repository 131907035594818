.descriptionbox {
    margin: 120px 170px;
}

.descriptionbox-navigator {
    display: flex;
}

.descriptionbox-nav-box {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
    width: 171px;
    height: 70px;
    border: 1px solid #d0d0d0;
    padding: 0px 70px;
}

.descriptionbox-nav-box.fade {
    background: #fbfbfb;
    color: #555;
}

.descriptionbox-description {
    display: flex;
    flex-direction: column;
    gap: 25px;
    border: 1px solid #d0d0d0;
    padding: 48px;
    padding-bottom: 70px;
}

/* Media Queries for Responsive Design */

@media screen and (max-width: 1200px) {
    .descriptionbox {
        margin: 80px 100px;
    }
}

@media screen and (max-width: 900px) {
    .descriptionbox {
        margin: 60px 50px;
    }

    .descriptionbox-nav-box {
        width: 140px;
        padding: 0px 50px;
    }
}

@media screen and (max-width: 600px) {
    .descriptionbox {
        margin: 40px 20px;
    }

    .descriptionbox-navigator {
        flex-direction: column;
    }

    .descriptionbox-nav-box {
        width: 100%;
        padding: 0px 20px;
        height: 50px;
    }

    .descriptionbox-description {
        padding: 20px;
        padding-bottom: 40px;
    }
}

@media screen and (max-width: 400px) {
    .descriptionbox {
        margin: 20px 10px;
    }

    .descriptionbox-nav-box {
        font-size: 14px;
    }

    .descriptionbox-description {
        font-size: 14px;
        gap: 15px;
    }
}