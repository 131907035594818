.newsletter {
    width: 100%;
    max-width: 1005px;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px auto;
    padding: -40px 20px;
    background: linear-gradient(180deg, #f3d6af 0%, #d79f2d22 60%);
    gap: 20px;
    box-sizing: border-box;
}

.newsletter h1 {
    color: #454545;
    font-size: 2rem;
    font-weight: 600;
    text-align: center;
    margin: 0;
}

.newsletter p {
    color: #454545;
    font-size: 1.1rem;
    text-align: center;
    margin: 0;
}

.newsletter div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: white;
    width: 100%;
    max-width: 600px;
    height: 50px;
    border-radius: 25px;
    border: 1px solid #e3e3e3;
    overflow: hidden;
}

.newsletter input {
    width: calc(100% - 180px);
    padding: 0 20px;
    border: none;
    outline: none;
    color: #616161;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
}

.newsletter div button {
    width: 150px;
    height: 50px;
    border: none;
    border-radius: 0 25px 25px 0;
    background-color: black;
    color: white;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.newsletter div button:hover {
    background-color: #333;
}

@media (max-width: 1024px) {
    .newsletter {
        margin-left:  35px;
    }

}

@media (max-width: 768px) {
    .newsletter {
        margin-left:  15px;

    }

    .newsletter h1 {
        font-size: 1.7rem;
    }

    .newsletter p {
        font-size: 1rem;
    }

    .newsletter div {
        height: auto;
        max-width: 400px;
    }

    .newsletter input {
        width: 100%;
        padding: 15px;
        border-radius: 25px;
    }

    .newsletter div button {
        width: 100%;
        height: 50px;

        border-radius: 25px;
    }
}

@media (max-width: 480px) {
    .newsletter h1 {
        font-size: 1.5rem;
    }

    .newsletter p {
        font-size: 0.9rem;
    }

    .newsletter div {
        max-width: 300px;
    }
}