.popular {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 20px;
    box-sizing: border-box;
    margin-top: -90px;
    margin-bottom: 80px;

}

.popular h1 {
    color: #171717;
    font-size: 2rem;
    font-weight: 500;
    text-align: center;
}

.popular hr {
    width: 150px;
    height: 4px;
    border-radius: 10px;
    background: #252525;
    margin-bottom: 20px;
}

.popular-item {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;
}

@media (max-width: 768px) {
    .popular h1 {
        font-size: 1.5rem;
    }

    .popular hr {
        width: 100px;
        height: 3px;
    }

    .popular-item {
        gap: 15px;
    }
}

@media (max-width: 480px) {
    .popular h1 {
        font-size: 1.2rem;
    }

    .popular hr {
        width: 80px;
        height: 2px;
    }

    .popular-item {
        gap: 10px;
    }
}
