/* ShopCategory.css */

.shopcategory-banner {
    display: block;
    margin: 50px auto;
    width: 1000px;
    max-width: 82%; /* Максимална ширина, като процент от контейнера */
    height: auto; /* Автоматично коригира височината спрямо пропорциите на изображението */
    flex-wrap: wrap; /* Позволява пренареждане на елементите при по-малки екрани */
}

.shopcategory-indexSort {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0px 150px;
}

.shopcategory-sort {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    border-radius: 40px;
    border: 1px solid #888;
    margin-right: 10px;
    background-color: #fff;
    position: relative;
    margin: 10px 0; /* Добавено, за да се осигури отстояние при подредба на мобилни устройства */
    width: 200px; /* Запазва фиксирана ширина на сортиращото меню */
}

.shopcategory-sort select {
    appearance: none; /* Премахване на стандартния вид на select */
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 16px;
    padding-right: 30px; /* Допълнително пространство за иконата */
}

.shopcategory-sort img {
    position: absolute;
    right: 10px;
    pointer-events: none; /* Иконата не пречи на кликовете */
}

.shopcategory-indexSort p{
    margin-right: 5px; /* Коригирано разстояние */
}

.shopcategory-products {
    margin: 20px 150px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Респонсивна мрежа */
    gap: 15px;
    row-gap: 80px;
}

.shopcategory-loadmore {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 110px auto;
}

.shopcategory-loadmore button {
    width: 233px;
    height: 69px;
    border-radius: 75px;
    background: #ededed;
    color: black;
    font-size: 18px;
    font-weight: 500;
    border: none;
    cursor: pointer;
    transition: background 0.3s, color 0.3s;
}

.shopcategory-loadmore button:hover {
    background: #ccc;
    color: #555;
}

/* Допълнителни стилове за сортиращото меню */
.shopcategory-sort select {
    padding: 10px 0; /* Вертикално подравняване */
    margin-left: 10px;
    margin-top: 1px;

}

.shopcategory-sort {
    width: 200px; /* Фиксирана ширина за сортиращото меню */
}

.shopcategory-loadmore button:disabled {
    background: #f0f0f0;
    color: #aaa;
    cursor: not-allowed;
}

@media (max-width: 768px) {
    .shopcategory-indexSort {
        flex-direction: column;
        align-items: flex-start; /* Подравнява елементите отляво за по-добра видимост */
        margin: 20px;
    }

    .shopcategory-indexSort p {
        font-size: 14px; /* Намаляване на шрифта за по-малки екрани */
        margin-left: 15px;
        margin: 10px auto;

    }

    .shopcategory-sort {
        width: 80%; /* Сортиращото меню да заема цялата ширина на родителя */
        justify-content: space-between; /* Осигурява повече пространство между текст и икона */
        padding: 8px 16px; /* Намаляване на padding за мобилни устройства */
        margin-top: 10px;
        margin-left: 15px;
        margin: 10px auto;

    }

    .shopcategory-sort select {
        font-size: 14px; /* Намаляване на шрифта за селектора */
    }

    .shopcategory-sort img {
        right: 8px; /* Корекция на позицията на иконата */
    }
}

/* За още по-малки екрани - смартфони */
@media (max-width: 480px) {
    .shopcategory-indexSort p {
        font-size: 12px; /* Допълнително намаляване на шрифта */
    }

    .shopcategory-sort {
        padding: 6px 12px; /* Още по-малко отстояние за компактен вид */
        font-size: 12px; /* Допълнително намаляване на шрифта */
    }

    .shopcategory-sort img {
        right: 6px; /* Още по-близо до ръба */
    }
}