.productdisplay {
    display: flex;
    margin: 0 70px;
}

.productdisplay-left {
    display: flex;
    gap: 17px;
}

.productdisplay-img-list {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.productdisplay-img-list img {
    height: 126px;
}

.productdisplay-main-img {
    width: 450px;
    height: 552px;
}

.productdisplay-right {
    margin: 0px 70px;
    display: flex;
    flex-direction: column;
}

.productdisplay-right h1 {
    color: #3d3d3d;
    font-size: 25px;
    font-weight: 700;
}

.productdisplay-right-star {
    display: flex;
    align-items: center;
    margin-top: 13px;
    gap: 5px;
    color: #1c1c1c;
    font-size: 16px;
}

.productdisplay-right-prices {
    display: flex;
    gap: 30px;
    font-size: 24px;
    font-weight: 700;
    margin: 40px 0px;
}

.productdisplay-right-price-old {
    color: #818181;
    text-decoration: line-through;
}

.productdisplay-right-price-new {
    color: #ff4141;
}

.productdisplay-right-size h1 {
    color: #656565;
    font-size: 20px;
    font-weight: 600;
    margin-top: 55px;
}

.productdisplay-right-sizes {
    display: flex;
    gap: 20px;
    margin: 30px 0px;
}

.productdisplay-right-sizes div {
    padding: 18px 24px;
    background: #fbfbfb;
    border: 1px solid #ebebeb;
    border-radius: 3px;
    cursor: pointer;
}

.productdisplay-right button {
    padding: 20px 40px;
    width: 200px;
    font-size: 16px;
    font-weight: 600;
    color: white;
    background: #fd742f;
    border: none;
    outline: none;
    cursor: pointer;
    margin-bottom: 40px;
}

.productdisplay-right-category {
    margin-top: 10px;
}

.productdisplay-right-category span {
    font-weight: 600;
}

/* ProductDisplay.css */

.productdisplay-right-sizes {
    display: flex;
    gap: 10px;
}

.size-option {
    padding: 10px 20px;
    border: 1px solid #ccc;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s, color 0.3s;
}

.size-option:hover {
    background-color: #f0f0f0;
}

.size-option.selected {
    background-color: #000;
    color: #fff;
    border-color: #000;
}


/* Media Queries for Responsive Design */

@media screen and (max-width: 1200px) {
    .productdisplay {
        margin: 0 40px;
    }

    .productdisplay-right {
        margin: 0px 40px;
    }

    .productdisplay-main-img {
        width: 400px;
        height: 490px;
    }
}

@media screen and (max-width: 1000px) {
    .productdisplay {
        flex-direction: column;
        align-items: center;
    }

    .productdisplay-left {
        gap: 12px;
    }

    .productdisplay-right {
        margin: 40px 0;
    }

    .productdisplay-main-img {
        width: 350px;
        height: 430px;
    }

    .productdisplay-img-list img {
        height: 110px;
    }
}

@media screen and (max-width: 600px) {
    .productdisplay {
        margin: 0 20px;
    }

    .productdisplay-left {
        flex-direction: column-reverse;
    }

    .productdisplay-img-list {
        flex-direction: row;
        gap: 10px;
    }

    .productdisplay-img-list img {
        height: 80px;
    }

    .productdisplay-main-img {
        width: 100%;
        height: auto;
    }

    .productdisplay-right h1 {
        font-size: 20px;
    }

    .productdisplay-right-prices {
        font-size: 20px;
    }

    .productdisplay-right-size h1 {
        font-size: 18px;
    }

    .productdisplay-right-sizes div {
        padding: 14px 18px;
    }

    .productdisplay-right button {
        padding: 15px 30px;
        width: 160px;
        font-size: 14px;
    }
}