.navbar {
    display: flex;
    justify-content: space-around;
    padding: 12px;
    box-shadow: 0 1px 3px -2px black;
}

.nav-logo {
    display: flex;
    align-items: center;
    gap: 0px;
}

.nav-logo img {
    height: 100px;   
    margin-top: 7px;
    margin-right: -15px;
}

.nav-logo p {
    color: #171717;
    font-weight: 600;
    font-size: 1.3rem;
    margin-top: 7px;

}

.nav-menu {
    display: flex;
    align-items: center;
    list-style: none;
    gap: 50px;
    color: #626262;
    font-size: 18px;
    font-weight: 500;
    text-decoration: none;

}

.nav-menu a {
    text-decoration: none;
}

.nav-menu li {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3px;
    cursor: pointer;

}

.nav-menu li a {
    color: #626262; /* Цвят по подразбиране на линковете */
    text-decoration: none; /* Премахване на underline */
  }
  
  .nav-menu li a:hover {
    color: #fd742f; /* Цвят при hover */
  }
  
.nav-menu hr {
    border: none;
    width: 80%;
    height: 3px;
    border-radius: 10px;
    background: #fd742f;
}

.nav-login-cart {
    display: flex;
    align-items: center;
    gap: 45px;
}

.nav-login-cart button {
    border: 1px solid #808080;
    width: 100px;
    height: 45px;
    outline: none;
    border-radius: 75px;
    color: #515151;
    background: white;
    font-size: 16px;
    font-weight: 500;
    margin-top: 5px;
}

.nav-login-cart button:active {
    background: #f3f3f3;
}

.nav-cart-img{
    height: 40px;
    margin-top: 5px;

}

.nav-cart-count {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    margin-top: -34px;
    margin-left: -45px;
    border-radius: 15px;
    font-size: 12px;
    background: #fd742f;
    color: white;
    
}


@media (max-width: 1024px) {
    .nav-menu {
        gap: 20px;
        font-size: 16px;
    }

    .nav-login-cart button {
        width: 100px;
        height: 40px;
        font-size: 16px;
    }
}

@media (max-width: 800px) {
    .navbar {
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }
    .nav-menu {
        flex-wrap: wrap;
        justify-content: center;
    }

}

@media (max-width: 500px) {
    .nav-logo p {
        font-size: 24px;
    }
    .nav-menu {
        font-size: 14px;
        gap: 10px;
    }
    .nav-login-cart button {
        width: 80px;
        height: 35px;
        font-size: 14px;
    }
}