.cartitems {
    margin: 100px 170px;
}

.cartitems hr {
    height: 3px;
    background: #e2e2e2;
    border: 0;
}

.cartitems-format-main {
    display: grid;
    grid-template-columns: 0.5fr 2fr 1fr 1fr 1fr 1fr;
    align-items: center;
    gap: 75px;
    padding: 20px 0;
    color: #454545;
    font-size: 18px;
    font-weight: 600;
}

.cartitems-format {
    display: grid;
    grid-template-columns: 0.5fr 2fr 1fr 1fr 1fr 1fr;
    align-items: center;
    gap: 75px;
    padding: 20px 0;
    color: #454545;
    font-size: 17px;
    font-weight: 500;
}

.carticon-product-icon {
    width: 62px;
    height: 62px;
    object-fit: cover;
}

.cartitems-quantity {
    width: 64px;
    height: 50px;
    border: 2px solid #ebebeb;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: #454545;
}

.carticon-remove-icon {
    width: 15px;
    margin: 0 40px;
    cursor: pointer;
}

.cartitems-down {
    display: flex;
    margin: 100px 0;
}

.cartitems-total {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-right: 200px;
    gap: 40px;
}
    
.cartitems-total-item {
    display: flex;
    justify-content: space-between;
    padding: 15px 0;
}

.cartitems-total button {
    width: 262px;
    height: 58px;
    outline: none;
    border: none;
    background: #fd742f;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
}

.cartitems-promocode {
    flex: 1;
    font-size: 16px;
    font-weight: 500;
}

.cartitems-promocode p {
    color: #555;
}

.cartitems-promobox {
    width: 504px;
    margin-top: 15px;
    padding-left: 20px;
    height: 58px;
    background: #eaeaea;
    display: flex;
}

.cartitems-promobox input {
    border: none;
    outline: none;
    background: transparent;
    font-size: 16px;
    width: 330px;
    color: #555;
}

.cartitems-promobox button {
    width: 170px;
    height: 58px;
    font-size: 16px;
    background: black;
    color: white;
    cursor: pointer;
}

@media(max-width: 1280px) {
    .cartitems {
        margin: 60px 50px;
    }
    .cartitems-format-main {
        gap: 40px;
        font-size: 16px;
    }
    .cartitems-format {
        gap: 40px;
        font-size: 15px;
    }
    .cartitems-down {
        flex-direction: column;
        gap: 50px;
    }
    .cartitems-total {
        margin-right: 0;
    }
    .cartitems-promobox {
        width: 100%;
    }
    .cartitems-promobox input {
        width: 65%;
    }
    .cartitems-promobox button {
        width: 35%;
    }
}

@media(max-width: 960px) {
    .cartitems-format-main {
        gap: 20px;
        font-size: 14px;
    }
    .cartitems-format {
        gap: 20px;
        font-size: 13px;
    }
    .cartitems-quantity {
        width: 50px;
        height: 40px;
    }
    .carticon-product-icon {
        width: 50px;
        height: 50px;
    }
}

@media(max-width: 740px) {
    .cartitems {
        margin: 50px 20px;
    }
    .cartitems-format-main {
        gap: 10px;
        font-size: 12px;
    }
    .cartitems-format {
        gap: 10px;
        font-size: 11px;
    }
    .cartitems-quantity {
        width: 40px;
        height: 30px;
    }
    .carticon-product-icon {
        width: 40px;
        height: 40px;
    }
    .carticon-remove-icon {
        margin: 0 20px;
    }
}