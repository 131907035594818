.relatedproducts {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 50px 0;
    margin-top: -100px;
}

.relatedproducts h1 {
    color: #171717;
    font-size: 30px;
    font-weight: 500;
}

.relatedproducts hr {
    width: 200px;
    height: 2px;
    border-radius: 10px;
    background: #252525;
}

.relatedproducts-item {
    margin-top: 50px;
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
    justify-content: center;
}

/* Media Queries for Responsive Design */

@media screen and (max-width: 1024px) {
    .relatedproducts h1 {
        font-size: 25px;
        margin-top: 20px;
    }

    .relatedproducts hr {
        width: 150px;
        height: 4px;
    }

    .relatedproducts-item {
        gap: 15px;
    }
}

@media screen and (max-width: 768px) {
    .relatedproducts {
        padding: 25px 0;
        margin-top: 20px;
    }

    .relatedproducts h1 {
        font-size: 25px;
    }

    .relatedproducts hr {
        width: 120px;
    }

    .relatedproducts-item {
        margin-top: 30px;
    }
}

@media screen and (max-width: 480px) {

    .relatedproducts h1 {
        font-size: 25px;
    }

    .relatedproducts hr {
        width: 100px;
        height: 3px;
    }

    .relatedproducts-item {
        margin-top: 20px;
        gap: 10px;
    }
}