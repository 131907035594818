.breadcrum {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #5e5e5e;
    font-size: 16px;
    font-weight: 600;
    margin: 30px 70px;
    text-transform: capitalize;
}

.breadcrum img {
    margin-top: 3px;
}
