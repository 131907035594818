.offers {
    width: 970px;
    height: 400px;
    min-height: 200px;
    display: flex;
    margin: 10px auto;
    margin-bottom: -50px;
    padding: 20px;
    background: linear-gradient(180deg, #f6cc84 0%, #e1ffea22 60%);
}

.offers-left {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.offers-left h1 {
    color: #171717;
    font-size: 2.5rem;
    font-weight: 600;
    margin: 0;
}

.offers-left p {
    color: #171717;
    font-size: 1.2rem;
    font-weight: 600;
    margin-top: 10px;
}

.offers-left button {
    width: 200px;
    height: 50px;
    border-radius: 25px;
    background-color: #fd742f;
    border: none;
    color: white;
    font-size: 1rem;
    font-weight: 500;
    margin-top: 20px;
    cursor: pointer;
}

.offers-right {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.offers-right img {
    max-width: 100%;
    height: auto;
    transition: all 0.3s ease;
}

@media (max-width: 1200px) {
    .offers {
        width: 90%;
        margin: 10px auto;
    }
    
    .offers-right img {
        max-width: 90%;
    }
}

@media (max-width: 992px) {
    .offers-right img {
        max-width: 80%;
    }
}

@media (max-width: 768px) {
    .offers {
        flex-direction: column;
        text-align: center;
        height: auto;
    }

    .offers-left, .offers-right {
        flex: none;
    }

    .offers-left button {
        margin: 20px auto;
    }

    .offers-right {
        justify-content: center;
        margin-top: 20px;
    }

    .offers-right img {
        max-width: 70%;
    }
}

@media (max-width: 480px) {
    .offers-left h1 {
        font-size: 2rem;
    }

    .offers-left p {
        font-size: 1rem;
    }

    .offers-left button {
        width: 180px;
        height: 45px;
        font-size: 0.9rem;
    }

    .offers-right img {
        max-width: 60%;
    }
}