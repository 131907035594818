.order-confirmation {
    max-width: 600px; /* Maximum width for the confirmation page */
    margin: 0 auto; /* Centering the container */
    margin-top: 30px;
    padding: 20px; /* Padding around the container */
    text-align: center; /* Center text alignment */
    background-color: #f9f9f9; /* Light background color */
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.order-confirmation h1 {
    color: #28a745; /* Success green color */
    font-size: 2rem; /* Size for the heading */
    margin-bottom: 10px; /* Space below the heading */
}

.order-confirmation p {
    font-size: 1.2rem; /* Font size for paragraphs */
    margin: 10px 0; /* Vertical margin for paragraphs */
    color: #333; /* Dark gray color for text */
}

.order-confirmation button {
    padding: 10px 20px; /* Padding for the button */
    font-size: 1rem; /* Font size for the button text */
    background-color: #007bff; /* Bootstrap primary color */
    color: white; /* White text color */
    border: none; /* No border */
    border-radius: 5px; /* Rounded corners for the button */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

.order-confirmation button:hover {
    background-color: #0056b3; /* Darker blue on hover */
}

@media (max-width: 600px) {
    .order-confirmation {
        padding: 15px; /* Reduced padding for smaller screens */
    }

    .order-confirmation h1 {
        font-size: 1.5rem; /* Smaller font size for heading */
    }

    .order-confirmation p {
        font-size: 1rem; /* Smaller font size for paragraphs */
    }

    .order-confirmation button {
        width: 100%; /* Full width for buttons on smaller screens */
    }
}