.footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 40px;
    padding: 20px;
    background-color: #dfdede;
}

.footer-logo {
    display: flex;
    align-items: center;
    gap: 15px;
}

.footer-logo img {
    height: 120px;
    margin-right: -25px;
}

.footer-logo p {
    color: #383838;
    font-size: 1.4rem;
    font-weight: 700;
}

.footer-links {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style: none;
    gap: 30px;
    color: #252525;
    font-size: 1rem;
}

.footer-links li {
    cursor: pointer;
}

.footer-social-icons {
    display: flex;
    gap: 15px;
}

.footer-icons-container {
    padding: 8px;
    padding-bottom: 4px;
    background: #fbfbfb;
    border: 1px solid #ebebeb;
}

.footer-copyright {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 100%;
    margin-bottom: 20px;
    color: #1a1a1a;
    font-size: 1rem;
}

.footer-copyright hr {
    width: 80%;
    border: none;
    border-radius: 10px;
    height: 2px;
    background: #c7c7c7;
}

/* Media Queries for Responsive Design */

@media screen and (max-width: 768px) {
    .footer {
        gap: 15px;
        margin-top: 30px;
    }

    .footer-logo img {
        height: 50px;
    }

    .footer-logo p {
        font-size: 1.2rem;
    }

    .footer-links {
        gap: 20px;
        font-size: 0.9rem;
    }

    .footer-social-icons {
        gap: 10px;
    }

    .footer-icons-container {
        padding: 6px;
        padding-bottom: 3px;
    }

    .footer-copyright {
        gap: 15px;
        font-size: 0.9rem;
    }
}

@media screen and (max-width: 480px) {
    .footer {
        gap: 10px;
        margin-top: 20px;
    }

    .footer-logo img {
        height: 40px;
    }

    .footer-logo p {
        font-size: 1rem;
    }

    .footer-links {
        gap: 15px;
        font-size: 0.8rem;
    }

    .footer-social-icons {
        gap: 8px;
    }

    .footer-icons-container {
        padding: 5px;
        padding-bottom: 2px;
    }

    .footer-copyright {
        gap: 10px;
        font-size: 0.8rem;
    }

    .footer-copyright hr {
        height: 1px;
    }
}